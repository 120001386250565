import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/design/design/node_modules/@primer/gatsby-theme-doctocat/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><inlineCode parentName="p">{`useOverlay`}</inlineCode>{` calls all of the relevant behavior Hooks that all `}<inlineCode parentName="p">{`Overlay`}</inlineCode>{` components & composite components should have and returns a ref to be passed down to the overlay's container.`}</p>
    <p>{`These behaviors include:`}</p>
    <ul>
      <li parentName="ul">{`Correctly positioning the component based on the values provided to `}<inlineCode parentName="li">{`positionSettings`}</inlineCode>{` and `}<inlineCode parentName="li">{`positionDeps`}</inlineCode>{`.`}</li>
      <li parentName="ul">{`Trapping focus`}</li>
      <li parentName="ul">{`Calling a user provided function when the user presses `}<inlineCode parentName="li">{`Escape`}</inlineCode></li>
      <li parentName="ul">{`Calling a user provided function when the user clicks outside of the container`}</li>
      <li parentName="ul">{`Focusing the either a user provided element, or the first focusable element in the container when it is opened.`}</li>
      <li parentName="ul">{`Returning focus to an element when container is closed`}</li>
    </ul>
    <p><strong parentName="p">{`Note:`}</strong>{` `}<inlineCode parentName="p">{`useOverlay`}</inlineCode>{` and `}<inlineCode parentName="p">{`Overlay`}</inlineCode>{` do not manage the open state of the overlay. We leave control of the open state up to the user. All behaviors are built with the assumption that the overlay will not be rendered on the page & fully unmounted when it is not visible. See the examples for details on how to conditionally render a component in JSX.`}</p>
    <h3>{`Usage`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-javascript",
        "metastring": "live noinline",
        "live": true,
        "noinline": true
      }}>{`const DemoOverlay = ({onClickOutside, initialFocusRef, returnFocusRef, ignoreClickRefs, onEscape, ...rest}) => {
  const overlayProps = useOverlay({returnFocusRef, onEscape, ignoreClickRefs, onClickOutside, initialFocusRef})
  return <Box height="200px" bg="green.4" {...overlayProps} {...rest} />
}

const DemoComponent = () => {
  const returnFocusRef = React.useRef(null)
  const initialFocusRef = React.useRef(null)
  const [isOpen, setIsOpen] = React.useState(false)
  const closeOverlay = () => setIsOpen(false)
  return (
    <>
      <Button ref={returnFocusRef} onClick={() => setIsOpen(!isOpen)}>
        toggle
      </Button>
      {isOpen && (
        <DemoOverlay
          returnFocusRef={returnFocusRef}
          ignoreClickRefs={[returnFocusRef]}
          initialFocusRef={initialFocusRef}
          onEscape={closeOverlay}
          onClickOutside={closeOverlay}
        >
          <Button>Button One</Button>
          <Button ref={initialFocusRef}>Button Two</Button>
        </DemoOverlay>
      )}
    </>
  )
}

render(<DemoComponent />)
`}</code></pre>
    <h4>{`UseOverlaySettings`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": "center"
          }}>{`Required`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`onEscapePress`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`function`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`required`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Function to call when user presses the Escape key`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`onOutsideClick`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`function`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`required`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Function to call when user clicks outside of the overlay`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`ignoreClickRefs`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`React.RefObject<HTMLElement> []`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`optional`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Refs to click clicks on in the `}<inlineCode parentName="td">{`onOutsideClick`}</inlineCode>{` function, useful for ignoring clicks on elements that trigger the overlay visibility.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`initialFocusRef`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`React.RefObject<HTMLElement>`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`optional`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Ref to focus when overlay is mounted.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`returnFocusRef`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`React.RefObject<HTMLElement>`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`required`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Ref to focus when overlay is unmounted. Important for accessibility.`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      